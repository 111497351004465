import Link from "next/link";

export default function Services() {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-8971d19 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="8971d19"
      data-element_type="section"
    >
      <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4afc576"
          data-id="4afc576"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-9c70927 elementor-widget elementor-widget-heading"
              data-id="9c70927"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h5 className="elementor-heading-title elementor-size-default">
                  our services
                </h5>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-af5e1cf elementor-widget elementor-widget-heading"
              data-id="af5e1cf"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">
                  Efficient, Effective & Elegant Business Solutions
                </h2>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-26304a2 elementor-widget elementor-widget-text-editor"
              data-id="26304a2"
              data-element_type="widget"
              data-widget_type="text-editor.default"
            >
              <div className="elementor-widget-container">
                Right from planning, and implementation to reviewing and
                certifying (such as 9001, ISO 27001, SOC2), just leave
                everything up to us. Using our extensive range of services we
                aim to automate ISO processes and improve your efficiency with
                the help of our unwavering support. Essentially, IS Auditr helps
                customers keep ISO at the center of all that they do. We aim to
                provide only the best ISO auditing services to all clients so
                that nothing gets in their way of sucess.
              </div>
            </div>
            <div
              className="elementor-element elementor-element-34a2ad7 elementor-tablet-align-center elementor-invisible elementor-widget elementor-widget-button"
              data-id="34a2ad7"
              data-element_type="widget"
              data-settings='{"_animation":"fadeInDown"}'
              data-widget_type="button.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-button-wrapper">
                  <Link
                    rel="dofollow"
                    href="/services/"
                    title="All Services"
                    className="elementor-button-link elementor-button elementor-size-md"
                  >
                    <span className="elementor-button-content-wrapper">
                      <span className="elementor-button-text">
                        all services
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-830edeb"
          data-id="830edeb"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-cbba5fd elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="cbba5fd"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-no">
                <div
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c8f2b2a"
                  data-id="c8f2b2a"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-66b4932 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box animated fadeInUp"
                      data-id="66b4932"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <span className="elementor-icon elementor-animation-">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="a00c0f7e-0dc4-4dfc-a840-b38fb3e06f89"
                                data-name="Layer 1"
                                width="94"
                                height="79"
                                viewBox="0 0 94 79"
                              >
                                <path
                                  d="M32.96,30.33a4.42,4.42,0,1,0-3.82,2.16,4.37,4.37,0,0,0,2.39-.72L47.21,47A1.0047,1.0047,0,0,0,48.6106,45.56Z"
                                  style={{
                                    fillRule: "evenodd",
                                  }}
                                ></path>
                                <path
                                  d="M88.2011,16.2769A22.21,22.21,0,0,0,56.7835,14.81L52.5,18.7192,48.2165,14.81a22.21,22.21,0,0,0-30.6084.622L3.76,2H18.05a1,1,0,0,0,0-2H0V18.04a1,1,0,0,0,2,0V3.08L16.2458,16.9083a22.3119,22.3119,0,0,0,2.0174,30.8454L52.5,79,86.7368,47.7537A22.3111,22.3111,0,0,0,88.2011,16.2769Zm-2.8125,30L52.5,76.2923,19.6114,46.2764A20.2626,20.2626,0,0,1,46.8683,16.2871l4.2835,3.9093a2,2,0,0,0,2.6964,0l4.2835-3.9093A20.2626,20.2626,0,0,1,85.3886,46.2764Z"
                                  style={{
                                    fillRule: "evenodd",
                                  }}
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h4 className="elementor-icon-box-title">
                              <span>consultancy</span>
                            </h4>
                            <p className="elementor-icon-box-description">
                              All your quality, security, health, environmental,
                              and more systems will be managed with the help of
                              our consultants’ refined knowledge
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="elementor-element elementor-element-7987272 elementor-align-center elementor-widget elementor-widget-button animated fadeInDown"
                      data-id="7987272"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInDown"}'
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-button-wrapper">
                          <Link href="/services/" title="Services">
                            <a
                              className="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                              role="button"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  Learn More
                                </span>
                              </span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d21d2c4"
                  data-id="d21d2c4"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-a9fb1d6 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box animated fadeInLeft"
                      data-id="a9fb1d6"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInLeft"}'
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <span className="elementor-icon elementor-animation-">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="af9ca67b-95be-4f98-9f6f-2a3c9fdcff41"
                                data-name="Layer 1"
                                width="81.3509"
                                height="75.4557"
                                viewBox="0 0 81.3509 75.4557"
                              >
                                <path
                                  d="M78.9252,22.1966A4.5,4.5,0,1,0,76.8238,30.69c2.9986,13.2136-2.9278,28.21-15.96,36.9757C44.34,78.7806,22.9031,75.5376,12.9226,60.7A30.6173,30.6173,0,0,1,9.1464,34.51c4.8142,1.9311,10.8685,1.7539,16.6905-.4468l.0109.0273q.6289-.2515,1.2652-.5439.5529-.2388,1.1012-.5022l-.0125-.026a33.254,33.254,0,0,0,5.1743-3.2978,28.36,28.36,0,0,0,8.3591-9.8678c1.5464-3.2907,1.902-6.6877.0615-9.07S36.5794,7.886,33.0048,8.551a26.9591,26.9591,0,0,0-9.781,4.2415l-.0011-.0016A39.7647,39.7647,0,0,0,7.986,31.7032a12.1035,12.1035,0,0,1-3.916-3.66C1.6747,24.4823,1.3992,19.89,3.028,15.2672A26.1139,26.1139,0,0,1,13.2317,2.6594,1,1,0,1,0,12.1155,1,28.1054,28.1054,0,0,0,1.1417,14.6025C-.6437,19.67-.4186,24.9537,2.4105,29.16a14.1486,14.1486,0,0,0,4.9113,4.483A32.57,32.57,0,0,0,11.263,61.8158c10.6948,15.9,33.4288,19.1383,50.7167,7.51,13.6576-9.1866,19.9884-24.987,16.7966-39.069a4.5,4.5,0,0,0,.1489-8.06Zm-56.3666-6.48.01.0124a26.3845,26.3845,0,0,1,10.8024-5.2119c3.32-.6176,5.6992.0069,6.8433,1.4879s1.1472,3.9412-.2889,6.9972a26.3857,26.3857,0,0,1-7.7716,9.1356,30.6156,30.6156,0,0,1-5.8766,3.592c-5.9205,2.5524-11.9271,2.7521-16.4927.8681A37.327,37.327,0,0,1,22.5586,15.7168Z"
                                  transform="translate(0 -0.8296)"
                                  style={{
                                    fillRule: "evenodd",
                                  }}
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h4 className="elementor-icon-box-title">
                              <span>Security</span>
                            </h4>
                            <p className="elementor-icon-box-description">
                              Identify, prevent, take action and limit the
                              damage that may concur and get more cost-efficient
                              by preparing beforehand
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="elementor-element elementor-element-23a97cb elementor-align-center elementor-widget elementor-widget-button animated fadeInDown"
                      data-id="23a97cb"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInDown"}'
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-button-wrapper">
                          <Link href="/services/" title="Services">
                            <a
                              className="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                              role="button"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  Learn More
                                </span>
                              </span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-ed48897 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="ed48897"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-no">
                <div
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c419116"
                  data-id="c419116"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-7205b0b elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box animated fadeInRight"
                      data-id="7205b0b"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInRight"}'
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <span className="elementor-icon elementor-animation-">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="bed5885e-0662-429e-ae8c-0b9a8e535c13"
                                data-name="Layer 1"
                                width="91.8547"
                                height="74.6427"
                                viewBox="0 0 91.8547 74.6427"
                              >
                                <path
                                  d="M90.7044,1.4952a4.5,4.5,0,1,0-.345,6.3547A4.5,4.5,0,0,0,90.7044,1.4952Z"
                                  transform="translate(0 0)"
                                ></path>
                                <path
                                  d="M61,11.1453a1,1,0,0,0,1,1H75.5877C65.2629,19.8084,61.428,27.2272,56.11,37.5163c-1.74,3.3672-3.64,7.0417-5.9777,11.1328C37.52,70.72,13.5082,72.5605.9954,72.6425A1.0012,1.0012,0,0,0,0,73.6457a.9909.9909,0,0,0,.9949.997c12.5657-.08,37.6686-1.893,50.8733-25.0013,2.4318-4.2555,4.3782-8.0176,6.1389-11.4208C63.4713,27.659,67.1484,20.5517,78,12.8665V28.1453a1,1,0,0,0,2,0v-18H62A1,1,0,0,0,61,11.1453Z"
                                  transform="translate(0 0)"
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h4 className="elementor-icon-box-title">
                              <span>Auditing</span>
                            </h4>
                            <p className="elementor-icon-box-description">
                              Get access to our experienced and independent
                              auditors who’ll take care of everything, from
                              pre-assessments to third-party audits
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="elementor-element elementor-element-499219f elementor-align-center elementor-invisible elementor-widget elementor-widget-button"
                      data-id="499219f"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInDown"}'
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-button-wrapper">
                          <Link href="/services/" title="Services">
                            <a
                              className="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                              role="button"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  Learn More
                                </span>
                              </span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-899526a"
                  data-id="899526a"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-5301529 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box animated fadeInDown"
                      data-id="5301529"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInDown"}'
                      data-widget_type="icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-icon-box-wrapper">
                          <div className="elementor-icon-box-icon">
                            <span className="elementor-icon elementor-animation-">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                id="ba7f2bb4-0985-40b2-89f5-846f3edc52c2"
                                data-name="Layer 1"
                                width="83.1669"
                                height="79.4606"
                                viewBox="0 0 83.1669 79.4606"
                              >
                                <path
                                  d="M57.3667,51.8579a4.5,4.5,0,1,0,6.3546.345A4.5,4.5,0,0,0,57.3667,51.8579Z"
                                  transform="translate(0 0)"
                                  style={{
                                    fillRule: "evenodd",
                                  }}
                                ></path>
                                <path
                                  d="M81.739,44.1024,30.33.9368a4,4,0,0,0-5.6355.4912L.9368,29.7228a4,4,0,0,0,.4912,5.6354L52.8369,78.5239a4,4,0,0,0,5.6355-.4912L82.23,49.7378A4,4,0,0,0,81.739,44.1024Zm-1.0405,4.3494L56.9407,76.7466a2,2,0,0,1-2.8177.2456L2.7141,33.8266a2,2,0,0,1-.2457-2.8178L26.2262,2.7141a2,2,0,0,1,2.8177-.2457L80.4529,45.634A2,2,0,0,1,80.6985,48.4518Z"
                                  transform="translate(0 0)"
                                  style={{
                                    fillRule: "evenodd",
                                  }}
                                ></path>
                              </svg>
                            </span>
                          </div>
                          <div className="elementor-icon-box-content">
                            <h4 className="elementor-icon-box-title">
                              <span>Training</span>
                            </h4>
                            <p className="elementor-icon-box-description">
                              Only the real world helps in tackling theoretical
                              concepts practically, developing the required
                              skills and becoming competent
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
                      className="elementor-element elementor-element-325817d elementor-align-center elementor-invisible elementor-widget elementor-widget-button"
                      data-id="325817d"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInDown"}'
                      data-widget_type="button.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-button-wrapper">
                          <Link href="/services/" title="Services">
                            <a
                              className="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                              role="button"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  Learn More
                                </span>
                              </span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}
